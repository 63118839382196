  <!-- #Change 5
  Side bar design
  -->
<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  > -->
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <!-- <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span> -->
              <!-- <div class="">
                <div class="s">
                  <b-img
                    :src="appLogoImage"
                    alt="logo"
                    style="width: 29px"
                  /> <div class="mr-2"><span style="color:#000000; font-weight: 3000;">PETRONAS</span></div>
                </div>
              </div> -->
              <div class="d-flex align-items-center">
                <span class="brand-logo">
                  <b-img
                    v-if="!isAerodyneDemo"
                    :src="appLogoImage"
                    alt="logo"
                  />
                  <b-img
                    v-if="isAerodyneDemo"
                    :src="require('@/assets/aerodyne-prism.png')"
                    alt="logo"
                  />
                </span>
                <h2
                  class="brand-text"
                  style="color: #000000;"
                >
                  <b-img
                    v-if="!isAerodyneDemo"
                    :src="require('@/assets/petronas.png')"
                    alt="logo"
                    width="130px"
                  />
                <!-- {{ appName }} -->
                </h2>
              </div>
              <!-- <div
                class="brand-text"
              >
                {{ appName }}
              </div> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <!-- <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                stroke="black"
                @click="toggleVerticalMenuActive"
              /> -->
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none discColor"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon discColor "

                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <!-- <vertical-nav-menu-items

        v-if="userRole.includes(1)"
        :items="superAdminItems"
        class="navigation navigation-main"
      />
      <vertical-nav-menu-items

        v-else-if="userRole.includes(2)"
        :items="adminItems"
        class="navigation navigation-main"
      /> -->
      <vertical-nav-menu-items
        :items="featuresItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<style scoped>
.discColor{
  color: #6E6B7B!important;
}
</style>

<script>
// import adminItems from '@/navigation/vertical/admin-nav'
// import superAdminItems from '@/navigation/vertical/super-admin-nav'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import featuresItems from '@/navigation/vertical/sidebar'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userRole: '',
      isAerodyneDemo: false
    }
  },
  // beforeMount(){
  //   // const userData = JSON.parse(localStorage.getItem('userData-Core'))
  //   this.userRole = JSON.parse(localStorage.getItem('userRole-Core'))
  // },
  mounted () {
    const path = window.location.host
    if (path.indexOf("aerodyne") >= 0) this.isAerodyneDemo = true
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // adminItems,
      // superAdminItems,
      featuresItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
